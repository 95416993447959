body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  background: white;
}

a {
  color: inherit;
}

p {
  font-size: 16px;
  font-weight: 300;
}

h1,
h2,
h3 {
  padding: 0;
  margin: 0;
  font-weight: 400;
}

*,
::after,
::before {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}
